<template>
  <section id="usuarios">
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component" />
      </div>
    </div>

    <div class="row align-items-center my-4">
      <div class="col-12 col-lg-12">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          {{ subtitle }}
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-5 mb-3 input-search">
        <input
          type="search"
          placeholder="Buscar usuario"
          v-model="filter.search"
          class="form-control input-custom"
          @keypress.enter="searchUser()"
          @keyup.delete="checkEmptyInput()"
          ref="searchInput"
        />
        <span class="input-search-icon">
          <font-awesome-icon icon="search" />
        </span>
      </div>


      <div class="col-12 col-lg-7 d-flex justify-content-end">
        <router-link
          to="/usuarios/add"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
        >
          <font-awesome-icon icon="plus" class="pe-2" />
          Crear usuario
        </router-link>
      </div>


    </div>

		<Loading 
			v-if="loading"
			pixeles="150px"
		/>

    <template v-if="!loading">   
      <div class="row">
        <div class="col-12">
          <div class="table-container">
            <table
              v-if="this.paginationArr.length > 0"
              class="table table-custom table-borderless table-custom-responsive mb-0"
            >
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col" class="text-center">RUT</th>
                  <th scope="col" class="text-center">Tipo de usuario</th>
                  <th scope="col" class="text-center">Sucursal</th>
                  <th scope="col" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, k) in userArrForCurrentPage" :key="k">
                  <td class="th th-nombre">
                    {{ user.primer_nombre }} {{ user.apellido_paterno }}
                  </td>
                  <td class="th th-rut text-center">
                    {{
                      user.rut_usuario
                        | VMask(user.rut_usuario.length == 7 ? mask_short : mask)
                    }}-{{ user.dv_usuario }}
                  </td>
                  <td class="th th-tipo-usuario text-center">
                    {{ user.privilegio[0]?.nombre_tipo }}
                  </td>
                  <td class="th th-sucursal text-center">
                    {{ user.sucursal[0]?.nombre_sucursal }}
                  </td>
                  <td class="th th-accion text-center">
                    <div
                      class="dropdown dropdown-custom d-flex justify-content-center"
                    >
                      <button
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide"
                        type="button"
                        data-bs-toggle="dropdown"
                      >
                        <font-awesome-icon icon="ellipsis-vertical" />
                      </button>
                      <ul
                        class="dropdown-menu"
                        :class="user.id_privilegio_fk == '1' && 'unactive'"
                      >
                        <li>
                          <a
                            @click="editUser(user)"
                            class="dropdown-item"
                            href="javascript:"
                          >
                            <font-awesome-icon
                              class="pe-2 color-main"
                              icon="pen"
                            />
                            Editar
                          </a>
                        </li>
                        <li>
                          <a
                            @click="deleteUserModal(user)"
                            class="dropdown-item"
                            href="javascript:"
                          >
                            <font-awesome-icon
                              class="pe-2 color-main"
                              icon="trash-alt"
                            />
                            Eliminar
                          </a>
                        </li>
                        <li>
                          <a
                            @click="openPasswordResetModal(user)"
                            class="dropdown-item"
                            href="javascript:"
                          >
                            <font-awesome-icon 
                              class="pe-2 color-main" 
                              :icon="['fas', 'key']" 
                            />
                            Restablecer<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contraseña
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <SinResultados 
              msg="Aceptar" 
              custom-click 
              @click="nuevaBusqueda" 
              v-if="!loading && paginationArr.length === 0"/>

          </div>
  
          <div
            class="users-list__pagination"
            v-if="this.pagination.page_count > 1"
          >
            <paginate
              v-model="pagination.actual_page"
              :page-count="pagination.page_count"
              :page-range="pagination.page_range"
              :click-handler="pagination.click_handler"
              :prev-text="pagination.prev_text"
              :next-text="pagination.next_text"
              :container-class="pagination.container_class"
              :page-class="pagination.page_class"
            >
            </paginate>
          </div>
          <!--  Modal que muestra el estado de la accion (exito o error) -->
          <Status
            v-if="open_modal_status"
            :msg="modal_status_msg"
            :status="modal_status"
            @close="closeStatus"
          />
          <!-- Modal pregunta -->
          <Question 
            v-if="open_question_modal"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelQuestion"
            @accept="acceptQuestion"
          />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
  import Status from "../Modales/Status.vue";
  import Question from "../Modales/Question.vue";

  import { mapState, mapActions } from "vuex";
  import SectionTabs from "../Section/SectionTabs.vue";

  //FF
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
        Status, 
        Question, 
        SectionTabs,
        Loading,
        SinResultados
      },

    data() {
      return {
        error_rut: false,
        pagination: {
          actual_page: 1,
          page_count: 0,
          page_range: 3,
          click_handler: this.changePage,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class: "pagination-item",
        },
        tab_component: [
          {
            name: "Inicio",
            route: "usuarios-list",
          },
          {
            name: "Agregar",
            route: "usuarios-add",
          },
        ],
        user: this.$ls.get("user"),
        subtitle: "Completa o edita la información de tus colaboradores. Si una persona ha sido desvinculada podrás eliminar su perfil.",
        users: [],
        users_search_list: [],
        selectedUser: null,
        no_results: "",
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        open_question_modal: false,
        question_modal_msg: "",
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        user_type_list: [],
        open_select_list: false,
        filter_selected: "Tipo de usuario",
        mask: "##.###.###",
        mask_short: "#.###.###",
        sort_list: true,
        id_user: this.$ls.get("user").id_usuario,
        user_limit: "15",
        acceptQuestion: () => {},

        //FF
        loading: false,
        filter: { 
          search: ''
        },
      };
    },

    watch: {
      "filter.search"(value) {
        if(value === '') {
          this.searchUser();
        }
      },
    },

    async created() {
      await this.searchUser();
    },

    computed: {
      ...mapState("usersModule", [
        "paginationArr",
        "userArrForCurrentPage",
      ]),
    },

    methods: {
      ...mapActions("usersModule", [
        "deleteUserAction",
        "getUserPagination",
        "getUsersInfoByIds",
        "deleteUserAction",
        "resetUserPasswordAction"
      ]),
      nuevaBusqueda(){
        this.filter.search = '';
        this.$refs.searchInput.focus();
        this.getUserPages();
      },

      // Buscar usuario y mostrar lista de resultado
      async getUserPages() {
        const data_ = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_user,
          nombre_usuario: this.filter.search,
          limite: this.user_limit,
        };
        const res = await this.getUserPagination(data_);
        if (!res) this.toastGenericError();
        this.pagination.page_count = this.paginationArr.length;
      },

      async changePage(page) {
        this.loading = true;
        this.pagination.actual_page = page;
        await this.getUsersByPage(this.pagination.actual_page);
        this.loading = false;
      },

      async getUsersByPage(page) {
        this.loading = true;
        let data_ = {
          id_empresa: this.id_empresa,
          id_usuario: this.paginationArr[page - 1].usuarios, // léase "ids usuarios"
        };
        const res = await this.getUsersInfoByIds(data_);
        if (!res) this.toastGenericError();
        this.loading = false;
      },

      async searchUser() {
        this.loading = true;
        this.selectedUser = null;
        await this.getUserPages();
        if (this.paginationArr.length > 0) {
          this.pagination.actual_page = 1;
          await this.getUsersByPage(this.pagination.actual_page);
        }
        this.loading = false;
      },

      // Funcion para obtener la lista de usuarios al borrar el input de busqueda
      editUser(user) {
        if (user.id_privilegio_fk !== "1") {
          this.$router.push({
            name: "usuarios-edit",
            params: { id: user.id_usuario },
          })
          .catch(() => {
            return;
          });
        }
      },

      // Funcion para preguntar si elimina usuario
      deleteUserModal(user) {
        if (user.id_privilegio_fk !== "1") {
          this.selectedUser = user;
          var name =
            user.primer_nombre.charAt(0).toUpperCase() +
            user.primer_nombre.slice(1);
          var lastname =
            user.apellido_paterno.charAt(0).toUpperCase() +
            user.apellido_paterno.slice(1);
          this.open_question_modal = true;
          this.question_modal_msg = `¿Quieres eliminar al usuario 
          ${name + " " + lastname }? <br/> No podrás deshacer esta acción.`;
          this.acceptQuestion = this.deleteUser;
        }
      },

      // Funcion para cerrar modal Status
      closeStatus() {
        this.open_modal_status = false;
        this.selectedUser = null;
      },

      // Funcion para "cancelar" la pregunta del Modal Question
      cancelQuestion() {
        this.open_question_modal = false;
      },

      // Funcion para "aceptar" la pregunta del modal Question (eliminar usuario)
      async deleteUser() {
        this.open_question_modal = false;
        this.loading = true;
        const data = {
          id_usuario: this.selectedUser.id_usuario,
          id_empresa_fk: this.id_empresa,
          id_eliminador: this.user.id_usuario,
        };
        const deleteUserRes = await this.deleteUserAction(data);
        if (deleteUserRes) {
          this.toastDeleteUserSuccess();
        } else {
          this.toastDeleteUserError();
        }
        this.loading = false;
      },

      openPasswordResetModal(selectedUser) {
        this.open_question_modal = true;
        this.selectedUser = selectedUser;
        this.question_modal_msg = `Al hacer clic en 'Aceptar' restablecerás la contraseña
          del usuario ${selectedUser.primer_nombre} ${selectedUser.apellido_paterno}. <br>
          ¿Deseas hacerlo?`;
        this.acceptQuestion = this.passwordReset;
      },

      async passwordReset() {
        this.loading = true;
        this.open_question_modal = false;
        const payload = {
          id_usuario: this.selectedUser.id_usuario,
          id_empresa: this.id_empresa
        };

        const res = await this.resetUserPasswordAction(payload);
        if (!res) {
          this.toastPasswordResetError();
        } else {
          // modal status..
          this.open_modal_status = true;
          this.modal_status_msg = `La contraseña del usuario 
            ${this.selectedUser.primer_nombre}  ${this.selectedUser.apellido_paterno} ha sido
            restablecida a los primeros 4 dígitos de su RUT: 
            <b>${this.selectedUser.rut_usuario.slice(0,4)}</b>`;
        }
        this.loading = false;

      },

      checkEmptyInput() {
        if (this.filter.search == "") {
          this.searchUser();
        }
      },

      toastDeleteUserSuccess() {
        this.$toast.open({
          message: "Usuario eliminado exitosamente.",
          type: "success",
          duration: 5000,
          position: "top-right",
        });
      },
      toastDeleteUserError() {
        this.$toast.open({
          message: "Error eliminado usuario. Por favor intenta nuevamente.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastGenericError() {
        this.$toast.open({
          message: "Error obteniendo información. Por favor recarga la página e intenta nuevamente.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastPasswordResetError() {
        this.$toast.open({
          message: "Error restableciendo contraseña. Por favor recarga la página e intenta nuevamente.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      }
    },
  };
</script>
